import authAxiosInstance from "../lib/interceptors/apiInterceptor";
import authGqlAxiosInstance from "../lib/interceptors/gqlApiInterceptor";

class MonitorService {
  async setFavoriteClient(
    monitorId: string,
    isFavorite: boolean,
  ): Promise<any> {
    try {
      // let apiUrl = `/monitor/${organizationId}/${monitorId}/favorite`;

      // const response = await authAxiosInstance.post(apiUrl);
      // return response.data;
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `mutation updateAccount($accountId: ID!, $account: AccountInput!) {
            updateAccount(accountId: $accountId, account: $account)
          }`,
          operationName: 'updateAccount',
          variables: {
            accountId: monitorId,
            account: {
              isFavorite,
            }
          },
        },
      });
      return response?.data?.data?.updateAccount;
    } catch (error) {
      throw error;
    }
  }

  async setGroupOnAccount(
    monitorId: string,
    groupId: string | null,
  ): Promise<any> {
    try {
      // let apiUrl = `/monitor/${organizationId}/${monitorId}/favorite`;

      // const response = await authAxiosInstance.post(apiUrl);
      // return response.data;
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `mutation updateAccount($accountId: ID!, $account: AccountInput!) {
            updateAccount(accountId: $accountId, account: $account)
          }`,
          operationName: 'updateAccount',
          variables: {
            accountId: monitorId,
            account: {
              groupId,
            }
          },
        },
      });
      return {
        response: response?.data?.data?.updateAccount,
        monitorId,
        groupId,
      };
    } catch (error) {
      throw error;
    }
  }

  async createMonitorLink(data: any): Promise<any> {
    try {
      // let apiUrl = `/monitor`;

      // const response = await authAxiosInstance.post(apiUrl, data);
      // return response.data;
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `mutation createAccount($orgId: ID!, $groupId: ID, $companySearchId: ID!) {
            createAccount(orgId: $orgId, groupId: $groupId, companySearchId: $companySearchId)
          }`,
          operationName: 'createAccount',
          variables: {
            orgId: data.orgId,
            companySearchId: data.creditSafeId,
            groupId: data.groupId,
          },
        },
      });
      return response?.data?.data?.createAccount;
    } catch (error) {
      throw error;
    }
  }

  async deleteMonitorLink(
    monitorId: string,
    organizationId: string
  ): Promise<any> {
    try {
      // let apiUrl = `/monitor/${organizationId}/${monitorId}`;

      // const response = await authAxiosInstance.delete(apiUrl);
      // return response.data;
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `mutation deleteAccount($accountId: ID!) {
            deleteAccount(accountId: $accountId)
          }`,
          operationName: 'deleteAccount',
          variables: {
            accountId: monitorId,
          },
        },
      });
      return response?.data?.data?.deleteAccount;
    } catch (error) {
      throw error;
    }
  }

  async getMonitorClient(
    monitorId: string,
    organizationId: string
  ): Promise<any> {
    try {
      // let apiUrl = `/monitor/${organizationId}/${monitorId}`;

      // const response = await authAxiosInstance.get(apiUrl);
      // return response.data;
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `query getAccount($accountId: ID!) {
            getAccount(accountId: $accountId) {
              id
              groupId
              company {
                id
                name
                legalName
                registrationNumber
                industry
                officeType
                status
                legalType
                registrationDate
                telephone
                address {
                  street
                  city
                  state
                  postalCode
                  country
                }
                simpleAddress
                taxId
                naics
                naicsDescription
                sic
                sicDescription
                sosCharterNumber
                profitStatus
                domesticStatus
                dbas
                websites
                subsidiaryCompanies {
                  id
                  name
                  type
                  status
                  registrationNumber
                  country
                  address {
                    simpleValue
                  }
                }
                affiliatedCompanies {
                  id
                  name
                  type
                  status
                  registrationNumber
                  country
                  address {
                    simpleValue
                  }
                }
              }
              latestPulseScores {
                trustPulse
                momentumPulse
                financialPulse
                creditPulse
                date
              }
              latestCreditsafeReport {
                report {
                  financialStatements {
                    profitAndLoss {
                      revenue
                    }
                    balanceSheet {
                      totalAssets
                    }
                    ratios {
                      totalDebtRatio
                      currentRatio
                    }
                  }
                  localFinancialStatements {
                    profitAndLoss {
                      totalRevenues
                    }
                    balanceSheet {
                      totalAssets
                    }
                    ratios {
                      netProfitMargin
                      quickRatio
                      currentRatio
                      returnOnEquity
                      zScore
                    }
                  }
                  creditScore {
                    currentCreditRating {
                      creditLimit {
                        currency
                        value
                      }
                    }
                  }
                  paymentData {
                    dbt {
                      dbt
                      industryDBT
                    }
                    dbtHistory {
                      date
                      dbt
                      industryDBT
                    }
                    tradePaymentSummary {
                      recentHighCredit
                      totalTradesBalance
                      totalTradesNumber
                    }
                    tradeLinesInformation {
                      total {
                        currency
                        range1to30
                        range31to60
                        range61to90
                        range91plus
                        current
                        number
                      }
                    }
                  }
                  negativeInformation {
                    taxLienDetails {
                      currency
                      filedDate
                      filingType
                      actionType
                      status
                      amount
                      filingNumber
                      jurisdiction
                    }
                    uccDetails {
                      filedDate
                      filingType
                      filingNumber
                      jurisdiction
                      filingOffice
                      securedPartyName
                      securedPartyAddress {
                        simpleValue
                      }
                      debtorName
                      debtorAddress {
                        simpleValue
                      }
                      relatedDocumentNumber
                      status
                    }
                    suits {
                      currency
                      filedDate
                      filingType
                      actionType
                      plaintiff
                      status
                      amount
                      filingNumber
                      courtName
                      courtAddress {
                        simpleValue
                      }
                      courtTelephone
                    }
                    judgmentDetails {
                      currency
                      filedDate
                      plaintiff
                      status
                      filingType
                      actionType
                      amount
                      filingNumber
                      courtName
                      courtAddress {
                        simpleValue
                      }
                      courtTelephone
                    }
                    mostRecentLegalFilings {
                      filedDate
                      legalFilingType
                      status
                      case
                    }
                  }
                  additionalInformation {
                    commentaries {
                      commentaryText
                      positiveOrNegative
                    }
                    extendedPaymentData {
                      paidMonthlyBalanceHistories {
                        paidCurrent
                        paid1To30
                        paid31To60
                        paid61To90
                        paid91Plus
                        date
                        currency
                      }
                      tpdMonthlyBalanceHistories {
                        balanceCurrent
                        range1to30
                        range31to60
                        range61to90
                        range91plus
                        date
                        currency
                      }
                      miniDashBoard {
                        pastDueSevereTotal
                        pastDueTotal
                        pastDuePercent
                        severelyPastDueTotal
                        activeCreditAmount
                      }
                      paidTradePaymentInformation {
                        invoicesPaid1To30Late
                        invoicesPaid31To60Late
                        invoicesPaid61To90Late
                        invoicesPaid91PlusLate
                        invoicesPaidOnTime
                        tradeLinesPaid
                        invoicesValuePaid1To30Late
                        invoicesValuePaid31To60Late
                        invoicesValuePaid61To90Late
                        invoicesValuePaid91PlusLate
                        invoicesValuePaidOnTime
                      }
                      groupTradePaymentInformation {
                        currency
                        balance130
                        balance3160
                        balance6190
                        balance91plus
                        balanceCurrent
                        tradeLines
                      }
                      paidGroupTradePaymentInformation {
                        invoicesPaid1To30Late
                        invoicesPaid31To60Late
                        invoicesPaid61To90Late
                        invoicesPaid91PlusLate
                        invoicesPaidOnTime
                        tradeLinesPaid
                        invoicesValuePaid1To30Late
                        invoicesValuePaid31To60Late
                        invoicesValuePaid61To90Late
                        invoicesValuePaid91PlusLate
                        invoicesValuePaidOnTime
                      }
                    }
                  }
                }
              }
              latestEnigmaReport {
                report {
                  card_transactions {
                    date_accessible
                    twelve_month {
                      average_monthly_count
                    }
                  }
                  card_revenue {
                    date_accessible
                    one_month {
                      average_monthly_amount
                    }
                    twelve_month {
                      average_monthly_amount
                    }
                  }
                  card_customers {
                    date_accessible
                    twelve_month {
                      average_daily_count
                    }
                  }
                  card_revenue_growth {
                    date_accessible
                    twelve_month {
                      rate
                    }
                  }
                  refunds {
                    date_accessible
                    twelve_month {
                      revenue_ratio_growth_rate
                    }
                  }
                }
              }
              latestPdlReport {
                report {
                  summary
                  employee_count
                  facebook_url
                  twitter_url
                  linkedin_url
                  latest_funding_stage
                  number_funding_rounds
                  last_funding_date
                  total_funding_raised
                  ticker
                  type
                  employee_growth_rate {
                    twelve_month
                  }
                  employee_churn_rate {
                    twelve_month
                  }
                  gross_departures_by_month {
                    date
                    count
                  }
                  gross_additions_by_month {
                    date
                    count
                  }
                  employee_count_by_month {
                    date
                    count
                  }
                }
                people {
                  full_name
                  job_title
                  mobile_phone
                  work_email
                  linkedin_url
                  facebook_url
                  twitter_url
                  experience {
                    company {
                      name
                    }
                    end_date
                  }

                }
              }
              latestNews {
                article {
                  articleId
                  url
                  imageUrl
                  country
                  title
                  description
                  summary
                  language
                  pubDate
                  addDate
                  refreshDate
                  medium
                  score
                  sentiment {
                    positive
                    negative
                    neutral
                  }
                  source {
                    domain
                    location {
                      country
                    }
                  }
                  reprint
                  keywords {
                    name
                    weight
                  }
                  topics {
                    name
                  }
                  categories {
                    name
                  }
                }
              }
              isFavorite
              creditExtended
              creditUsed
              term {
                days
                discount
                discountDays
              }
              assignedUsers {
                type
                id
                name
                email
                phone
              }
              companyContacts {
                role
                name
                email
                phone
              }
              created
            }
          }`,
          operationName: 'getAccount',
          variables: {
            accountId: monitorId,
          },
        },
      });
      const companyId = response?.data?.data?.getAccount?.company?.id;
      const account = response?.data?.data?.getAccount ?? {};
      if (companyId) {
        const response = await authGqlAxiosInstance({
          method: 'post',
          data: {
            query: `query getPulseScoresByCompanyId($companyId: ID!) {
              getPulseScoresByCompanyId(companyId: $companyId) {
                id
                trustPulse
                momentumPulse
                financialPulse
                creditPulse
                date
              }
            }`,
            operationName: 'getPulseScoresByCompanyId',
            variables: {
              companyId,
            },
          },
        });
        account.allPulseScores = response?.data?.data?.getPulseScoresByCompanyId;

        // TEMPORARY CODE
        authGqlAxiosInstance({
          method: 'post',
          data: {
            query: `query getRiskProbabilitiesByAccountId($accountId: ID!) {
              getRiskProbabilitiesByAccountId(accountId: $accountId) {
                bankrupt {
                  modelStatus
                  model
                  probability
                  confidence
                  explanation {
                    feature
                    feature_value
                    feature_percentile
                    shap_proba
                    shap_magnitude
                    feature_impact_direction
                    feature_impact_magnitude
                  }
                }
                late_payment {
                  modelStatus
                  model
                  probability
                  confidence
                  explanation {
                    feature
                    feature_value
                    feature_percentile
                    shap_proba
                    shap_magnitude
                    feature_impact_direction
                    feature_impact_magnitude
                  }
                }
              }
            }`,
            operationName: 'getRiskProbabilitiesByAccountId',
            variables: {
              accountId: monitorId,
            },
          },
        });
        // END TEMPORARY CODE
      }

      return account;
    } catch (error) {
      throw error;
    }
  }

  async updateMonitorAssignees(
    monitorId: string,
    organizationId: string,
    data: any
  ): Promise<any> {
    try {
      // let apiUrl = `/monitor/${organizationId}/${monitorId}/assignees`;

      // const response = await authAxiosInstance.put(apiUrl, data);
      // return response.data;
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `mutation updateAccount($accountId: ID!) {
            updateAccount(accountId: $accountId)
          }`,
          operationName: 'updateAccount',
          variables: {
            accountId: monitorId,
            account: {
              isFavorite: data.isFavorite,
            }
          },
        },
      });
      return response?.data?.data?.updateAccount;
    } catch (error) {
      throw error;
    }
  }
}

export default new MonitorService();
